import React from "react";
import "./style/footer.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const Footer = () => {
  return (
    <footer className="footer bg-dark">
      <Container>
        <Row className="footer-row">
          <Col className="summary">
            <h4>من نحن ؟</h4>
            <p className="summary-paragraph">
              موقع إخباري ثقافي اجتماعي رياضي يعمل على إبراز الصورة الإيجابية
              للأشخاص ذوي الإعاقة، ويسلط الضوء على أهم الإنجازات والنجاحات
              والشخصيات الرائدة بغرض تحسين نظرة المجتمع تجاه هذه الفئة الملهمة.
            </p>
          </Col>
          <Col className="links">
            <h4>اهم الصفحات</h4>
            <div style={{display:"flex", flexWrap:"wrap", gap:"7px"}}>
              {[
                { path: "/news/local", label: "أخبار محلية" },
                { path: "/news/arab", label: "أخبار دولية" },
                { path: "/news/international", label: "أخبار عالمية" },
                { path: "/news/sports", label: "أخبار رياضية" },
                { path: "/news/Cultural", label: "أخبار ثقافية" },
                { path: "/talks", label: "كلمات رواد التنمية" },
                { path: "/news/inspiringPersonalities", label: "شخصيات ملهمة" },
                { path: "/disabled-people-ads", label: "إعلانات لذوي الإعاقة" },
                { path: "/", label: "أخبار منظمات ذوي الإعاقة" },
              ].map((link, index) => (
                <Link key={index} className="pagesLinks" to={link.path}>
                  {link.label}
                </Link>
              ))}
            </div>
          </Col>
          <Col className="contact">
            <h4>طرق التواصل</h4>
            <div className="phone">
              <FaPhone size={25} color="rgba(255, 255, 255, 0.719)" />
              <a
                href="https://wa.me/+201020615648"
                target="_blank"
                rel="noopener noreferrer"
                title="تواصل علي الواتس اب"
              >
                +201020615648
              </a>
            </div>
            <div className="phone">
              <MdEmail size={25} color="rgba(255, 255, 255, 0.719)" />
              <a href="mailto:newsdpo@gmail.com">newsdpo@gmail.com</a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
